(<template>
  <div id="app"
       class="main-app"
       :class="[{
         'main-app--overflow': mainOverflow,
         'input-focused': inputIsFocusedMob,
         'main-app--is-godmode': $isGodmode()
       }, $getMarketplaceName()]">
    <template v-if="isGlobal404">
      <ff />
    </template>
    <template v-else>
      <tt-progress v-if="mainProgressActive" />
      <file-progress v-if="uploadProgressActive" />
      <component :is="mobileComponentShown"
                 v-if="!mainProgressActive && mobileComponentShown"
                 :single-prop="mobileComponentProps" />
      <focus-loop :is-visible="modalIsVisible"
                  :auto-focus="true">
        <sk-modal v-if="showModal"
                  v-show="!mobileComponentShown">
          <component :is="modalComponent"
                     :data="modalData"
                     @closemodal="closeModal"
                     @startprogress="startProgress"
                     @stopprogress="stopProgress" />
        </sk-modal>
      </focus-loop>
      <sidebar v-if="showSidebar">
        <component :is="sidebarComponent"
                   :data="sidebarData"
                   @closemodal="closeSidebar"
                   @startprogress="startSidebarProgress"
                   @stopprogress="stopSidebarProgress" />
      </sidebar>
      <tooltip-root v-if="showTooltip">
        <component :is="toolTipComponent"
                   :data="toolTipData"
                   @closemodal="closeTooltip"
                   @startprogress="startTooltipProgress"
                   @stopprogress="stopTooltipProgress" />
      </tooltip-root>
      <toast v-if="showToast">
        <component :is="toastComponent"
                   :data="toastData"
                   @closemodal="closeToast"
                   @startprogress="startToastProgress"
                   @stopprogress="stopToastProgress" />
      </toast>
      <sk-info-modal v-if="showInfoModal"
                     v-show="!mobileComponentShown" />
      <router-view v-show="delayedShow" />
    </template>
  </div>
</template>)

<script>
  import {mapGetters} from 'vuex';
  import constants from '@/modules/constants';
  import WSManager from '@/modules/ws_data_module';
  import ErrorPage from '@/pages/shared_pages/errors/404';
  import FileProgress from '@/components/shared_components/FileProgress';
  import Sidebar from '@/components/shared_components/Sidebar';
  import TooltipRoot from '@/components/shared_components/TooltipRoot';
  import Toast from '@/components/shared_components/Toast';
  // ============================= SIDEBARS ========================
  import InterpreterShortInfo
    from '@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/InterpreterShortInfo';
  // ============================= MODALS ========================
  import PostAssignSummary from '@/components/booking_components/modals/SummaryModal';
  import AwardAssignment from '@/components/assignment_components/one_assignment/modal_components/AwardModal';
  import ApplyAssignment from '@/components/assignment_components/one_assignment/modal_components/ApplyModal';
  import CallProblems from '@/components/assignment_components/one_assignment/modal_components/CallProblemsModal';
  import RejectAssignment from '@/components/assignment_components/one_assignment/modal_components/RejectModal';
  import WithdrawAssignment from '@/components/assignment_components/one_assignment/modal_components/WithdrawModal';
  import AbandonAssignment from '@/components/assignment_components/one_assignment/modal_components/AbandonModal';
  import DeclineInterpreterAssign
    from '@/components/assignment_components/one_assignment/modal_components/DeclineInterpreterModal';
  import CancelAssignment
    from '@/components/assignment_components/one_assignment/modal_components/CancelAssignmentModal';
  import CancelProject
    from '@/components/project_components/one_project/modal_components/CancelProjectModal';
  import ConfirmEditModal from '@/components/assignment_components/one_assignment/modal_components/ConfirmEditModal';
  import CooperationAgreementModal from '@/components/shared_components/modals/CooperationAgreementModal';
  import SkillModal from '@/components/profile_components/edit/SkillModal';
  import AvatarName from '@/components/profile_components/edit/AvatarNameModal';
  import PersonalInfo from '@/components/profile_components/edit/PersonalInfoModal';
  import PersonAbout from '@/components/profile_components/edit/AboutModal';
  import ToolsModal from '@/components/profile_components/edit/ToolsModal';
  import CheckboxesModal from '@/components/profile_components/edit/CheckboxesListModal';
  import SchoolsModal from '@/components/profile_components/edit/SchoolsModal';
  import ExperienceModal from '@/components/profile_components/edit/ExperienceModal';
  import CoursesModal from '@/components/profile_components/edit/CoursesModal';
  import DeleteAccountModal from '@/components/profile_components/edit/DeleteAccountModal';
  import DeleteEnterpriseAdminAccountModal from '@/components/profile_components/edit/DeleteEnterpriseAdminAccountModal';
  import AddEmployeeModal from '@/components/enterprise/employees/AddModal';
  import AddDepartmentModal from '@/components/enterprise/departments/AddModal';
  import AddInvoiceModal from '@/components/payments_components/modals/AddInvoiceModal';
  import PaymentMethodOptionModal from '@/components/payments_components/modals/PaymentMethodOptionModal';
  import AddDepartmentInvoiceModal from '@/components/payments_components/modals/AddDepartmentInvoiceModal';
  import AddClientModal from '@/components/manage_clients/AddClientModal';
  import AddAddress from '@/components/enterprise/addresses/AddAddress';
  import RemovePayoutModal from '@/components/payouts/confirms/RemoveConfirmation';
  import OpenAssignModal from '@/components/dashboard_components/OpenAssignModal';
  import VerifyPhoneModal from '@/components/profile_components/edit/VerifyPhoneModal';
  import AttendersList from '@/components/assignment_components/one_assignment/modal_components/EditAttenders';
  import WelcomeModal from '@/components/shared_components/modals/WelcomeModal';
  import DrivingLicenseModal from '@/components/shared_components/modals/DrivingLicenseModal';
  import HelpModal from '@/components/shared_components/modals/HelpModal';
  import AccessibilityModal from '@/components/assignment_components/one_assignment/modal_components/AccessibilityModal';
  // import HelpcenterModal from '@/components/shared_components/modals/HelpcenterModal';
  import SendSmsModal from '@/components/shared_components/modals/SendSmsModal';
  import AvailabilityEventsModal from '@/components/shared_components/modals/AvailabilityEventsModal';
  import ActiveJobConfirmModal from '@/components/shared_components/modals/ActiveJobConfirmModal';
  import UndefinedErrorModal from '@/components/shared_components/modals/UndefinedErrorModal';
  import InterpreterIdModal from '@/components/shared_components/modals/InterpreterIdModal';
  import InterpreterBlockedModal from '@/components/shared_components/modals/InterpreterBlockedModal';
  import SendDetailsModal from '@/components/shared_components/modals/SendDetailsModal';
  import RemoveConfirmationModal from '@/components/shared_components/modals/RemoveConfirmationModal';
  import ContactAdminModal from '@/components/shared_components/modals/ContactAdminModal';
  import OutdatedClientModal from '@/components/shared_components/modals/OutdatedClientModal';
  import HotlineInfoModal from '@/components/hotline/HotlineInfoModal';
  import UnsuitableJobModal from '@/components/assignment_components/one_assignment/modal_components/UnsuitableJobModal';
  import HasConfirmedJobsModal from '@/components/preferences/private_events/modals/PrivateEventsHasConfirmedJobsError';
  import HasAppliedJobsModal from '@/components/preferences/private_events/modals/PrivateEventsHasAppliedJobsError';
  import DocumentsUploadModal from '@/components/documents/documents/DocumentsUploadModal';
  import TestVideoApplyErrorModal from '@/components/test_video/TestVideoApplyErrorModal';
  import DocumentsSignModal from '@/components/documents/signatures/DocumentsSignModal';
  import SignaturesApplyErrorModal from '@/components/documents/signatures/SignaturesApplyErrorModal';
  import AddressErrorModal from '@/components/documents/signatures/AddressErrorModal';
  import PayoutInfoModal from '@/components/payouts/payout_info_wizard/PayoutInfoModal';
  import PermissionModal from '@/components/assignment_components/one_assignment/modal_components/PermissionModal';
  import SameJobModal from '@/components/booking_components/modals/SameJobModal';
  import FeedbackJobsModal from '@/components/assignment_components/one_assignment/modal_components/FeedbackJobsModal';
  import AddTravelExpenseModal
    from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/feedback_sections/travel_expenses_section/AddTravelExpenseModal';
  import JobPreviewModal from '@/components/calendars/JobPreviewModal';
  import AddTicketModal from '@/components/ticket_system/modals/AddTicketModal';
  import AddTicketMessageModal from '@/components/ticket_system/modals/AddTicketMessageModal';
  import AddTicketSuccessModal from '@/components/ticket_system/modals/AddTicketSuccessModal';
  import SystemLogModal from '@/components/assignment_components/one_assignment/system_log/SystemLogModal';
  import AssignmentInfoEditModal
    from '@/components/assignment_components/one_assignment/assignment_info/modals/AssignmentInfoEditModal';
  import AssignmentInfoEditErrorModal
    from '@/components/assignment_components/one_assignment/assignment_info/modals/AssignmentInfoEditErrorModal';
  import WithdrawAsSupplierModal
    from '@/components/assignment_components/one_assignment/awarded_assignment/modals/WithdrawAsSupplierModal';
  import ConfirmationModal from '@/components/shared_components/modals/ConfirmationModal';
  import MessageModal from '@/components/shared_components/modals/MessageModal';
  import AddPrivateEventModal from '@/components/calendars/AddPrivateEventModal';
  import ProjectInfoEditModal from '@/components/project_components/one_project/modals/ProjectInfoEditModal';
  import NewsListModal from '@/components/shared_components/modals/NewsListModal';
  import ImagePrevieModal from '@/components/shared_components/modals/ImagePreviewModal';
  import SelectLanguageModal from '@/components/shared_components/modals/SelectLanguageModal';
  import ConfirmDownloadModal from '@/components/shared_components/modals/ConfirmDownloadModal';
  import TicketsList from '@/components/assignment_components/one_assignment/TicketsList';
  import DialectRequestModal from '@/components/assignment_components/one_assignment/modal_components/DialectRequestModal';
  import DangerousEditModal from '@/components/assignment_components/one_assignment/modal_components/DangerousEditModal';
  import AdvancedFiltersModal from '@/components/interpreters/AdvancedFilters';
  import InterpreterBlockedByListModal
    from '@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/modals/InterpreterBlockedByListModal';
  import BlockInterpreterModal from '@/components/shared_components/modals/BlockInterpreterModal';
  import ExportConfirmationModal from '@/components/assignment_components/all_assignments/modals/ExportConfirmationModal';
  import MoveCustomerEnterpriseModal from '@/components/customers/modals/MoveCustomerEnterpriseModal';
  import ConvertUpgradeBuyerModal from '@/components/customers/modals/ConvertUpgradeBuyerModal';
  import MergeCustomersModal from '@/components/customers/modals/merge/MergeCustomersModal';
  import EnterpriseEditModal from '@/components/enterprise/EnterpriseEditModal';
  import CreateEditSupplierModal from '@/components/suppliers/CreateEditSupplierModal';
  import CreateInterpretationSkillModal from '@/components/interpretation_skills/CreateInterpretationSkillModal';
  import NotesModal from '@/components/interpretation_skills/notes/NotesModal';
  import DisableWithdraw from '@/components/assignment_components/one_assignment/assignment_info/modals/AssignmentWithdraw';
  import DialectsModal from '@/components/assignment_components/one_assignment/modal_components/DialectsModal';
  import SubtaskRejectionModal from '@/components/project_components/one_project/modals/SubtaskRejectionModal';
  import EditDeadlineModal from '@/components/project_components/one_project/modals/EditDeadlineModal';

  const isBrowser = new Function('try {return this===window;}catch(e){ return false;}');

  export default {
    asyncData({store, route}) {
      const promisesArr = [];
      if (store.state.TTAuthStore.loggedIn && !store.getters['UserInfoStore/userHasEmail'] && route.name != 'ForceLogout') {
        promisesArr.push(store.dispatch('UserInfoStore/fetchUserInfo', store.getters['UserInfoStore/userUid']));
      }
      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      ff: ErrorPage,
      sidebar: Sidebar,
      'tooltip-root': TooltipRoot,
      toast: Toast,
      'file-progress': FileProgress,
      'contact-admin': ContactAdminModal,
      'send-details': SendDetailsModal,
      'post-summary': PostAssignSummary,
      'award-assign': AwardAssignment,
      'apply-assign': ApplyAssignment,
      'call-problems': CallProblems,
      'reject-assign': RejectAssignment,
      'active-job-confirm': ActiveJobConfirmModal,
      'withdraw-assign': WithdrawAssignment,
      'disable-withdraw-assign': DisableWithdraw,
      'abandon-assign': AbandonAssignment,
      'decline-int-assign': DeclineInterpreterAssign,
      'cancel-assign': CancelAssignment,
      'cancel-project': CancelProject,
      'add-skill': SkillModal,
      'avatar-name-modal': AvatarName,
      'person-info': PersonalInfo,
      'person-about': PersonAbout,
      'tools-modal': ToolsModal,
      'checkboxes-modal': CheckboxesModal,
      'schools-modal': SchoolsModal,
      'experience-modal': ExperienceModal,
      'courses-modal': CoursesModal,
      'delete-account-modal': DeleteAccountModal,
      'delete-enterprise-admin-account-modal': DeleteEnterpriseAdminAccountModal,
      'add-employee': AddEmployeeModal,
      'add-department': AddDepartmentModal,
      'add-address-modal': AddAddress,
      'add-invoice-modal': AddInvoiceModal,
      'payment-method-option': PaymentMethodOptionModal,
      'add-department-invoice-modal': AddDepartmentInvoiceModal,
      'add-client': AddClientModal,
      'confirm-payout-removal': RemovePayoutModal,
      'confirm-edit': ConfirmEditModal,
      'dangerous-edit': DangerousEditModal,
      'remove-confirm': RemoveConfirmationModal,
      'confirmation-modal': ConfirmationModal,
      'message-modal': MessageModal,
      'open-assign-modal': OpenAssignModal,
      'verify-phone': VerifyPhoneModal,
      'attenders-list': AttendersList,
      'welcome-modal': WelcomeModal,
      'driving-modal': DrivingLicenseModal,
      'help-modal': HelpModal,
      // 'helpcenter-modal': HelpcenterModal,
      'accessibility-modal': AccessibilityModal,
      'interpreter-id-modal': InterpreterIdModal,
      'error-modal': UndefinedErrorModal,
      'inter-blocked': InterpreterBlockedModal,
      'send-sms': SendSmsModal,
      'hotline-info-modal': HotlineInfoModal,
      'unsuitable-job-modal': UnsuitableJobModal,
      'upload-doc-modal': DocumentsUploadModal,
      'has-confirmed-jobs-modal': HasConfirmedJobsModal,
      'has-applied-jobs-modal': HasAppliedJobsModal,
      'availability-events-modal': AvailabilityEventsModal,
      'video-test-apply-error-modal': TestVideoApplyErrorModal,
      'sign-doc-modal': DocumentsSignModal,
      'signatures-apply-error-modal': SignaturesApplyErrorModal,
      'address-error-modal': AddressErrorModal,
      'permission-modal': PermissionModal,
      'same-job-modal': SameJobModal,
      'feedback-jobs-modal': FeedbackJobsModal,
      'payout-info-modal': PayoutInfoModal,
      'add-travel-expense': AddTravelExpenseModal,
      'job-preview-modal': JobPreviewModal,
      'add-ticket-modal': AddTicketModal,
      'add-ticket-message-modal': AddTicketMessageModal,
      'add-ticket-success-modal': AddTicketSuccessModal,
      'system-log-modal': SystemLogModal,
      'outdated-client-modal': OutdatedClientModal,
      'withdraw-as-supplier-modal': WithdrawAsSupplierModal,
      'assignment-info-edit-modal': AssignmentInfoEditModal,
      'project-info-edit-modal': ProjectInfoEditModal,
      'assignment-info-edit-error-modal': AssignmentInfoEditErrorModal,
      'add-private-event-modal': AddPrivateEventModal,
      'interpreter-short-info': InterpreterShortInfo,
      'news-list-modal': NewsListModal,
      'select-language-modal': SelectLanguageModal,
      'image-preview-modal': ImagePrevieModal,
      'confirm-download-modal': ConfirmDownloadModal,
      'tickets-list': TicketsList,
      'dialect-request-modal': DialectRequestModal,
      'advanced-filters-modal': AdvancedFiltersModal,
      'int-blocked-by-modal': InterpreterBlockedByListModal,
      'block-interpreter-modal': BlockInterpreterModal,
      'export-confirmation-modal': ExportConfirmationModal,
      'move-customer-enterprise-modal': MoveCustomerEnterpriseModal,
      'convert-upgrade-buyer-modal': ConvertUpgradeBuyerModal,
      'merge-customers-modal': MergeCustomersModal,
      'enterprise-edit-modal': EnterpriseEditModal,
      'create-edit-supplier-modal': CreateEditSupplierModal,
      'create-inter-skill-modal': CreateInterpretationSkillModal,
      'cooperation-agreement-modal': CooperationAgreementModal,
      'subtask-rejection-modal': SubtaskRejectionModal,
      'edit-deadline-modal': EditDeadlineModal,
      'notes-modal': NotesModal,
      'dialects-modal': DialectsModal
    },
    metaInfo() {
      const faviconPath = this.userIsManager ? this.$getString('metaData', 'adminFaviconPath') : this.$getString('metaData', 'faviconPath');
      const firstPartBannerContent = this.userIsInterpreter ? this.$gettext('Get notified immediately') : this.$gettext('Stay on top');
      const secondPartBannerContent = this.userIsInterpreter ? this.$gettext('about new assignments!') : this.$gettext('of all your assignments!');
      const isMobileApp = this.$store.state.mobileApp;

      return {
        title: (() => {
          const title = this.$getString('metaData', 'longTitle');
          if (this.userIsManager) return this.$getString('metaData', 'adminTitle');
          if (this.userFirstName && this.userLastName) {
            return this.userEnterpriseName
              ? `${this.userEnterpriseName}, ${this.fullName} - ${title}`
              : `${this.fullName} - ${title}`;
          }
          return title;
        })(),
        meta: [
          {charset: 'utf-8'},
          {name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'},
          {name: 'X-UA-Compatible', content: 'IE=edge'},
          // smartbanner tags with dynamic content
          {name: 'smartbanner:title', content: 'Salita'},
          {name: 'smartbanner:author', content: firstPartBannerContent},
          {name: 'smartbanner:price', content: secondPartBannerContent},
          {name: 'smartbanner:button', content: this.$gettext('Download')},
          {name: 'smartbanner:close-label', content: this.$gettext('Close')},
          {name: 'smartbanner:button-url-google', content: 'app_android'},
          ...(isMobileApp ? [{name: 'smartbanner:enabled-platforms', content: 'none'}] : []),
          {vmid: 'description', name: 'description', content: this.$getString('metaData', 'description')}
        ],
        link: [
          {rel: 'apple-touch-icon', sizes: '57x57', href: `${faviconPath}/apple-icon-57x57.png`},
          {rel: 'apple-touch-icon', sizes: '60x60', href: `${faviconPath}/apple-icon-60x60.png`},
          {rel: 'apple-touch-icon', sizes: '72x72', href: `${faviconPath}/apple-icon-72x72.png`},
          {rel: 'apple-touch-icon', sizes: '76x76', href: `${faviconPath}/apple-icon-76x76.png`},
          {rel: 'apple-touch-icon', sizes: '114x114', href: `${faviconPath}/apple-icon-114x114.png`},
          {rel: 'apple-touch-icon', sizes: '120x120', href: `${faviconPath}/apple-icon-120x120.png`},
          {rel: 'apple-touch-icon', sizes: '144x144', href: `${faviconPath}/apple-icon-144x144.png`},
          {rel: 'apple-touch-icon', sizes: '152x152', href: `${faviconPath}/apple-icon-152x152.png`},
          {rel: 'apple-touch-icon', sizes: '180x180', href: `${faviconPath}/apple-icon-180x180.png`},
          {rel: 'icon', sizes: '192x192', href: `${faviconPath}/android-icon-192x192.png`},
          {rel: 'icon', sizes: '32x32', href: `${faviconPath}/favicon-32x32.png`},
          {rel: 'icon', sizes: '96x96', href: `${faviconPath}/favicon-96x96.png`},
          {rel: 'icon', sizes: '16x16', href: `${faviconPath}/favicon-16x16.png`},
          {rel: 'shortcut icon', type: 'image/ico', href: `${faviconPath}/favicon.ico`}
        ]
      };
    },
    data() {
      return {
        authToken: '',
        uID: '',
        mainOverflow: false,
        scrollValue: '',
        mobileComponentScroll: '',
        delayedShow: true,
        punchOutRedirectUrl: '',
        restoredClasses: [],
      };
    },
    computed: {
      ...mapGetters({
        inputIsFocused: 'GlobalStore/getInputIsFocused'
      }),
      ...mapGetters('UserInfoStore', [
        'userUid',
        'userEmail',
        'fullName',
        'userHasEmail',
        'userFullPhone',
        'userEnterpriseName',
        'userLocale',
        'currentProvider',
        'userIsInterpreter',
        'userFirstName',
        'userLastName',
        'adminFirstName',
        'adminLastName',
        'userIsManager'
      ]),
      isGlobal404() { return this.$store.state.GlobalStore.globalFF; },
      isLoggedIn() { return this.$store.state.TTAuthStore.loggedIn; },
      isGodmode() { return this.$store.state.TTAuthStore.godmode; },
      clientWidth() { return this.$store.state.GlobalStore.clientWidth; },
      mainProgressActive() { return this.$store.state.GlobalStore.mainProgressActive; },
      uploadProgressActive() { return this.$store.state.uploadProgress.active; },
      mobileComponentShown() { return this.$store.state.MobileComponentStore.mobileFullHeight.component; },
      mobileComponentProps() { return this.$store.state.MobileComponentStore.mobileFullHeight.data; },
      inputIsFocusedMob() { return this.clientWidth < 768 && this.inputIsFocused; },
      serverVersion() { return this.$store.state.serverVersion; },
      // MODAL DATA
      modalComponent() {
        return this.$store.getters['ModalStore/modalComponent'];
      },
      modalData() {
        return this.$store.getters['ModalStore/modalData'] || {};
      },
      showWithModal() {
        return !(this.clientWidth < 768 && this.$store.state.ModalStore.modal.component);
      },
      showModal() {
        return this.$store.state.ModalStore.modal.component;
      },
      showInfoModal() {
        return this.$store.state.InfoModalStore.infoModalData.text;
      },
      // SIDEBAR DATA
      showSidebar() {
        return this.$store.state.SidebarStore.sidebar.component;
      },
      sidebarComponent() {
        return this.$store.getters['SidebarStore/sidebarComponent'];
      },
      sidebarData() {
        return this.$store.getters['SidebarStore/sidebarData'] || {};
      },
      // TOOLTIP DATA
      showTooltip() {
        return Object.keys(this.$store.state.TooltipRootStore.tooltip.data).length > 0;
      },
      toolTipComponent() {
        return this.$store.getters['TooltipRootStore/tooltipComponent'];
      },
      toolTipData() {
        return this.$store.getters['TooltipRootStore/tooltipData'] || {};
      },
      // TOAST DATA
      showToast() {
        return Object.keys(this.$store.state.ToastStore.toast.data).length > 0;
      },
      toastComponent() {
        return this.$store.getters['ToastStore/toastComponent'];
      },
      toastData() {
        return this.$store.getters['ToastStore/toastData'] || {};
      },
      modalIsVisible() {
        return this.$store.state.ModalStore.modal.component !== '';
      },
    },
    watch: {
      userEmail(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadHelpscoutChat();
        }
      },
      mainProgressActive() {
        if (!(!this.mainProgressActive && !this.mobileComponentShown && this.showWithModal)) {
          this.delayedShow = false;
        } else {
          setTimeout(() => {
            this.delayedShow = true;
          }, 0);
        }
      },
      showWithModal() {
        if (!(!this.mainProgressActive && !this.mobileComponentShown && this.showWithModal)) {
          this.delayedShow = false;
        } else {
          setTimeout(() => {
            this.delayedShow = true;
          }, 0);
        }
      },
      authToken() {
        this.handleToken();
        this.$root.$defaultChannel = this.$webSocket.subscribe((data) => {
          // data contains the data sent by the backend
          data.message && data.message.event ? data.message.event.notification_identifier = data.notification_identifier : '';
          WSManager.setDataFromSockets(this, data.message || data);
        }, 'DefaultChannel');
      },
      mobileComponentShown() {
        if (!(!this.mainProgressActive && !this.mobileComponentShown && this.showWithModal)) {
          this.delayedShow = false;
        } else {
          setTimeout(() => {
            this.delayedShow = true;
          }, 0);
        }
        if (this.mobileComponentShown) {
          this.mobileComponentScroll = window.pageYOffset || document.documentElement.scrollTop;
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
        } else {
          setTimeout(() => {
            document.documentElement.scrollTop = this.mobileComponentScroll;
            document.body.scrollTop = this.mobileComponentScroll;
            this.mobileComponentScroll = '';
          }, 0);
        }
      },
      showModal() {
        this.showModal ? this.mainOverflow = true : this.mainOverflow = false;
      },
      mainOverflow() {
        const self = this;
        if (this.mainOverflow) {
          this.scrollValue = window.pageYOffset || document.documentElement.scrollTop;
          document.documentElement.scrollTop = 0;
          document.body.scrollTop = 0;
        } else {
          setTimeout(() => {
            document.documentElement.scrollTop = this.scrollValue;
            document.body.scrollTop = this.scrollValue;
            self.scrollValue = '';
          }, 0);
        }
      },
      userLocale() {
        this.$store.dispatch('fetchSelectList', 'language');
        this.$store.dispatch('fetchSelectList', 'qualification');
        if (this.$store.getters['UserInfoStore/userIsEnterpriseMember'] && this.$store.getters['UserInfoStore/enterpriseId']) {
          this.$store.dispatch('EnterpriseStore/getCategories');
        }
        if (this.$store.getters['EditProfileStore/getUserRole'] === 'server') {
          this.$store.dispatch('EditProfileStore/fetchExpertiseAreas', this.userLocale);
          this.$store.dispatch('EditProfileStore/fetchInterpretationMethods', this.userLocale);
          this.$store.dispatch('EditProfileStore/fetchTranslationMethods', this.userLocale);
        }
        if (this.$store.getters['UserInfoStore/userIsInterpreter']) {
          this.$store.dispatch('TTSignaturesStore/getDocumentsForSigning');
        }
        this.$router.replace({
          name: this.$route.name,
          query: {...this.$route.query},
          params: {...this.$route.params, lang: this.userLocale}
        });
      },
      punchOutRedirectUrl() {
        this.$store.commit('TTAuthStore/setPunchout', this.punchOutRedirectUrl);
      }
    },
    methods: {
      selectAllClickableUI() {
        return Array.from(document.querySelectorAll('a')).concat(Array.from(document.querySelectorAll('button')));
      },
      // MODAL METHODS
      closeModal() {
        this.$store.dispatch('ModalStore/closeModal');
      },
      startProgress() {
        this.$store.commit('ModalStore/startModalProgress');
      },
      stopProgress() {
        this.$store.commit('ModalStore/stopModalProgress');
      },
      // SIDEBAR METHODS
      closeSidebar() {
        this.$store.dispatch('SidebarStore/closeSidebar');
      },
      startSidebarProgress() {
        this.$store.commit('SidebarStore/startSidebarProgress');
      },
      stopSidebarProgress() {
        this.$store.commit('SidebarStore/stopSidebarProgress');
      },
      // Tooltip METHODS
      closeTooltip() {
        this.$store.dispatch('TooltipRootStore/closeTooltip');
      },
      startTooltipProgress() {
        this.$store.commit('TooltipRootStore/startTooltipProgress');
      },
      stopTooltipProgress() {
        this.$store.commit('TooltipRootStore/stopTooltipProgress');
      },
      // Toast METHODS
      closeToast() {
        this.$store.dispatch('ToastStore/closeTooltip');
      },
      startToastProgress() {
        this.$store.commit('ToastStore/startToastProgress');
      },
      stopToastProgress() {
        this.$store.commit('ToastStore/stopToastProgress');
      },
      // OTHER
      handleToken() {
        if (this.authToken) {
          this.$store.dispatch('TTAuthStore/logIn', {token: this.authToken, uid: this.uID, punchOutRedirectUrl: this.punchOutRedirectUrl});
          if (!this.userHasEmail || this.userUid != this.uID) {
            this.$store.commit('GlobalStore/startProgress');
            this.$store.dispatch('UserInfoStore/fetchUserInfo', this.uID).then(() => {
              this.$router.replace(this.$makeRoute({name: 'SignIn'}));
              this.$store.commit('GlobalStore/stopProgress');
            });
          }
        } else {
          this.$store.commit('TTAuthStore/logOut');
          if (this.$route.matched.some((record) => record.meta.requiresAuth)) {
            this.$router.replace(this.$makeRoute({name: 'SignIn'}));
          }
        }
      },
      setClientWidth() {
        this.$store.commit('GlobalStore/setClientWidth', window.innerWidth);
      },
      addGoogleMapScript(appendMapTo) {
        if (!window || !window.google || !window.google.maps) {
          const element = document.createElement('script');
          const appendToElement = appendMapTo || document.querySelector('head');
          element.src = `https://maps.googleapis.com/maps/api/js?key=${constants.GOOGLE_KEY}&libraries=places`;
          element.type = 'text/javascript';
          appendToElement.appendChild(element);
        }
      },
      loadGTMScript(key) {
        const win = window;
        const doc = document;
        const script = doc.createElement('script');
        const dl = 'dataLayer';
        win[dl] = win[dl] || [];
        win[dl].push({
          event: 'gtm.js',
          'gtm.start': new Date().getTime()
        });
        if (!key) {
          return;
        }
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtm.js?id=${key}`;
        doc.head.insertBefore(script, doc.head.childNodes[0]);
        const noscript = doc.createElement('noscript');
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${key}" height="0" width="0" style="display:none;visibility:hidden"></iframe`;
        doc.body.insertBefore(noscript, doc.body.childNodes[0]);
      },
      loadAntiFlickerSnippet(key) {
        const doc = document;
        const script = doc.createElement('script');
        script.innerHTML = `(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
                            h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
                            (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
                            })(window,document.documentElement,'async-hide','dataLayer',4000,
                            {'${key}':true});`;
        doc.head.appendChild(script);
      },
      loadHelpscoutChat() {
        const key = this.userIsInterpreter
          ? constants.SUPPLIER_HELPSCOUT_KEY
          : constants.HELPSCOUT_KEY;

        if (isBrowser() && window.Beacon && key) {
          window.Beacon('destroy');
          setTimeout(() => {
            window.Beacon('init', key);
          });
        }
      },
      checkServerVersion(interval = 1000 * 60 * 60) {
        this.$store.dispatch('checkServerVersion').then((serverVersion) => {
          if (!this.serverVersion) this.$store.commit('setServerVersion', serverVersion);
          else if (this.serverVersion !== serverVersion) {
            this.$store.commit('ModalStore/setModal', {
              component: 'outdated-client-modal',
              width: 410
            });
          }
        });
        setTimeout(() => {
          this.checkServerVersion(interval);
        }, interval);
      },
      getRedirectLink() {
        const {country, lang: locale} = this.$route.params;
        const currentHost = window.location.hostname;
        const hostname = currentHost.replace('tikktalk.com', 'salita.no');
        return `https://${hostname}/${locale}/${country}/auth/sign_in`;
      }
    },
    beforeMount() {
      if (this.currentProvider && !this.$isOnDomain(this.currentProvider) && this.userIsInterpreter && this.isLoggedIn) {
        this.$store.dispatch('TTAuthStore/logOut').then(() => {
          window.location.replace(this.getRedirectLink());
        });
        return;
      }
      if (this.$route.name != 'ForceLogout') {
        if (this.$cookieManager.getCookie('uID')) {
          this.uID = this.$cookieManager.getCookie('uID');
        } else {
          this.uID = '';
          this.$cookieManager.deleteCookie('uID');
        }
        if (this.$cookieManager.getCookie('authToken') && this.$cookieManager.getCookie('uID')) {
          this.authToken = this.$cookieManager.getCookie('authToken');
        } else {
          this.authToken = '';
          this.$cookieManager.deleteCookie('authToken');
        }
        if (this.$cookieManager.getCookie('godmode')) {
          this.$store.commit('TTAuthStore/setGodMode', true);
        } else {
          this.$store.commit('TTAuthStore/setGodMode', false);
        }
        this.punchOutRedirectUrl = this.$cookieManager.getCookie('punch_out_redirect_url');
        this.handleToken();
      }

      const savedFeatureFlags = localStorage.getItem('featureFlags');
      this.$store.commit('UserInfoStore/setFeatureFlags', JSON.parse(savedFeatureFlags));

      this.loadGTMScript(constants.GTM_KEY);
      this.loadAntiFlickerSnippet(constants.GTM_KEY);
      this.addGoogleMapScript();
      this.checkServerVersion();
    },
    mounted() {
      this.$cookieManager.setCookie('kubi', 'enabled', {path: '/', expires: 2592000});
      if (process?.env?.NODE_ENV === 'production' && !this.$cookieManager.getCookie('godmode') && this.$analytics) {
        this.$analytics.fbq.init('1635963520048800', {em: this.$store.state.UserInfoStore.mainUserInfo.email});
      }
      document.addEventListener('click', (e) => {
        if (e.target.matches('input[type=\'button\'], input[type=\'submit\'], input[type=\'reset\'], input[type=\'button\'], input[type=\'radio\'], input[type=\'checkbox\'], button, a') && e.target.className.indexOf('not-click-blurable') == -1) {
          e.target.blur();
        }
      });
      document.addEventListener('touchstart', () => {
        this.$store.commit('GlobalStore/setTouchScreen');
      });
      window.addEventListener('storage', () => {
        if (document.hidden && ((this.authToken && localStorage.getItem('authToken') != this.authToken) || (!this.authToken && localStorage.getItem('authToken')))) {
          window.location.reload();
        }
      });
      this.setClientWidth();
      window.addEventListener('resize', this.setClientWidth);
      if (Element && !Element.prototype.matches) {
        const proto = Element.prototype;
        proto.matches = proto.matchesSelector
          || proto.mozMatchesSelector || proto.msMatchesSelector
          || proto.oMatchesSelector || proto.webkitMatchesSelector;
      }
      // Helpscout
      this.loadHelpscoutChat();
      // End Helpscout

      // Restored styles for accessbility
      const accessibilityOptions = localStorage.getItem('accessibilityOptions');
      const docElement = document.documentElement;
      if (accessibilityOptions) {
        this.restoredClasses = JSON.parse(accessibilityOptions);
        this.restoredClasses.forEach((itemStyle) => {
          docElement.classList.add(itemStyle);
        });
      }
    },
    updated() {
      // Activate hightlight mode for new component
      if (localStorage.getItem('accessibilityHighlightLinks')) {
        this.selectAllClickableUI().forEach((el) => {
          el.classList.add('highlighted-links');
          el.children[0] != undefined ? el.children[0].classList.add('highlighted-links--children') : '';
        });
      }
    }
  };
</script>

<!--SK COMPONENTS STYLES -->
<style src="@assets/css/sk_btns.css"></style>
<style src="@assets/css/sk_links.css"></style>
<style src="@assets/css/sk_notes.css"></style>
<style src="@assets/css/sk_progress.css"></style>
<style src="@assets/css/sk_custom_selects.css"></style>
<style src="@assets/css/sk_form_fields.css"></style>
<style src="@assets/css/sk_checkbox.css"></style>
<style src="@assets/css/sk_mobiledatepicker.css"></style>
<style src="@assets/css/sk_mobileselect.css"></style>
<style src="@assets/css/sk_mobilemultiselect.css"></style>
<style src="@assets/css/sk_radio.css"></style>
<style src="@assets/css/sk_datepicker.css"></style>
<style src="@assets/css/sk_timepicker.css"></style>
<style src="@assets/css/sk_toggler.css"></style>
<style src="@assets/css/sk_modal.css"></style>
<style src="@assets/css/sk_infomodal.css"></style>
<style src="@assets/css/sk_upload-file.css"></style>
<style src="@assets/css/sk_month_calendar.css"></style>
<style src="@assets/css/sk_pagination.css"></style>
<style src="@assets/css/sk_tooltip.css"></style>
<!--ClINET STYLE VARIABLES -->
<style src="@assets/css/variables.css"></style>
<style src="@assets/css/variables_salita.css"></style>
<!--TT COMPONENTS STYLES -->
<style src="@assets/css/secondary_select.css"></style>
<style src="@assets/css/assignment-type-btn.css"></style>
<style src="@assets/css/mobile_link.css"></style>
<style src="@assets/css/burger_btn.css"></style>
<style src="@assets/css/price_stripe.css"></style>
<style src="@assets/css/edit_pencil_btn.css"></style>
<style src="@assets/css/rotate_btn.css"></style>
<style src="@assets/css/accessibility_btn.css"></style>
<style src="@assets/css/feedback_toggle_btn.css"></style>
<style src="@assets/css/add_plus_btn.css"></style>
<style src="@assets/css/chat_messages.css"></style>
<style src="@assets/css/favourite_icon.css"></style>
<style src="@assets/css/favourite_btn.css"></style>
<style src="@assets/css/new_assignment_btn.css"></style>
<style src="@assets/css/print.css"></style>
<style src="@assets/css/clinet_styles.css"></style>
<style src="@assets/css/attested_by_police_icon.css"></style>
<style src="@assets/css/copy_to_clipboard.css"></style>
<style src="@assets/css/posting.css"></style>
<style src="@assets/css/nav_btn.css"></style>
<style src="@assets/css/tab.css"></style>
<style src="@assets/css/navigation_header.css"></style>
<style src="@assets/css/accessibility.css"></style>
<style src="@assets/css/daterange_picker.css"></style>

<style>
  /* cyrillic-ext */
  @font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr6DRASf6M7VBj.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* cyrillic */
  @font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr4TRASf6M7VBj.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* devanagari */
  @font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr5DRASf6M7VBj.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }

  /* greek-ext */
  @font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr6TRASf6M7VBj.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }

  /* greek */
  @font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr5jRASf6M7VBj.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }

  /* vietnamese */
  @font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr6jRASf6M7VBj.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  }

  /* latin-ext */
  @font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr6zRASf6M7VBj.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-weight: 400;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr5TRASf6M7Q.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* cyrillic-ext */
  @font-face {
    font-weight: 700;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVadyBx2pqPIif.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  /* cyrillic */
  @font-face {
    font-weight: 700;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVYNyBx2pqPIif.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  /* devanagari */
  @font-face {
    font-weight: 700;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVZdyBx2pqPIif.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
  }

  /* greek-ext */
  @font-face {
    font-weight: 700;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVaNyBx2pqPIif.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }

  /* greek */
  @font-face {
    font-weight: 700;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVZ9yBx2pqPIif.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }

  /* vietnamese */
  @font-face {
    font-weight: 700;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVa9yBx2pqPIif.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  }

  /* latin-ext */
  @font-face {
    font-weight: 700;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVatyBx2pqPIif.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }

  /* latin */
  @font-face {
    font-weight: 700;
    font-style: normal;
    font-family: 'Noto Sans';
    src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVZNyBx2pqPA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  /* dyslexic */
  @font-face {
    font-weight: normal;
    font-style: normal;
    font-family: 'Opendyslexic';
    src: local('Opendyslexic'), url('./assets/tikktalk/fonts/OpenDyslexic.woff') format('woff');
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: inherit;
    font-family: inherit;
    text-decoration: none;
  }

  *:focus {
    outline: none;
  }

  *.is-hidden,
  *.is-example {
    display: none !important;
  }

  *.is-visualy-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    visibility: hidden !important;
  }

  *[hidden] {
    display: none;
  }

  html,
  body {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 14px;
    font-family: 'Noto Sans', sans-serif;
    direction: ltr;
  }

  html {
    margin-top: 0 !important;
    line-height: 1.4em;
  }

  body {
    font-family: inherit;
    line-height: inherit;
  }

  u {
    text-decoration: underline;
  }

  *::-moz-focus-inner {
    border: 0;
  }

  button {
    border: 0;
    background-color: transparent;
    font-size: inherit;
    font-family: inherit;
    letter-spacing: inherit;
    cursor: pointer;
    transition: 0.25s;
  }

  a:focus {
    color: inherit;
    text-decoration: none;
  }

  a:focus,
  button:focus {
    outline: 0;
  }

  input:not([type=checkbox]):not([type=radio]),
  textarea {
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-appearance: none;
  }

  input[type='submit'] {
    -webkit-border-radius: 3px !important;
    border-radius: 3px !important;
  }

  textarea {
    overflow: auto;
    resize: none;
  }

  .main-app {
    height: 100%;
  }

  .main-app--overflow {
    overflow: hidden;
  }

  .overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pointer {
    cursor: pointer;
  }

  .pac-container {
    border: 1px solid #f1f1f1;
    border-radius: 0;
    color: #333;
    box-shadow: none;
    font-family: inherit;
  }

  .pac-container > .pac-item {
    padding: 10px;
    padding-left: 0;
    border-top: 0;
    color: inherit;
    font-size: 15px;
    cursor: pointer;
  }

  .pac-container > .pac-item > .pac-icon.pac-icon-marker {
    width: 35px;
    height: 25px;
    margin-top: 0;
    margin-right: 0;
    background-image: url(~@assets/imgs/assignment_types/assignment_page/location_icon_ie.png);
    background-position: 50% 50%;
    background-size: auto 22px;
    background-repeat: no-repeat;
  }

  .pac-container > .pac-item > .pac-item-query {
    color: inherit;
    font-size: 15px;
  }

  .pac-container > .pac-item > .pac-matched {
    font-weight: 500;
  }

  .pac-container > .pac-item > span:last-child {
    margin-left: 5px;
    color: #999;
  }

  /* styles for smartbanner on mobile devices */
  .smartbanner {
    z-index: 11;
    background-color: #fff;
    font-family: inherit;
  }

  .smartbanner__button {
    top: 18px;
    padding: 2px 6px;
    border: 1px solid #ff5b24;
    background-color: #fff;
    color: #ff5b24;
    font-size: 13px;
  }

  .smartbanner__exit::before,
  .smartbanner__exit::after {
    height: 15px;
    background-color: #000;
  }

  .smartbanner__icon {
    width: 57px;
    height: 57px;
    background-size: 57px 57px;
  }

  @media (max-width: 767px) {
    .BeaconContainer ~ .BeaconFabButtonFrame {
      display: none !important;
    }
  }
</style>
