<script>
  import PostAssignmentPrototype from '@/prototypes/PostAssignmentPrototype';
  import {mapGetters} from 'vuex';

  export default {
    extends: PostAssignmentPrototype,
    computed: {
      ...mapGetters('PostInterpretationStore', [
        'bookingFormProgress'
      ]),
      adminInfoSectionTitle() {
        const template = this.$gettext('%{domain} - Admin tools');
        return this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')});
      },
      personalInformationSection() {
        return {
          title: this.$gettext('My information'),
          lines: [{
            components: [{
              name: 'booker-section',
              condition: this.showBookerSection
            }]
          }, {
            components: [{
              name: 'department-attenders-section',
              condition: this.showDepartmentAttendersSection
            }]
          }, {
            components: [{
              name: 'owner-real-name-section',
              condition: this.showOwnerRealNameSection
            }]
          }]
        };
      },
      invoiceInformationSection() {
        return {
          title: this.$gettext('Invoice Information'),
          lines: [{
            components: [{
              name: 'booking-reference-section',
              condition: this.showBookingReferenceSection
            }]
          }, {
            components: [{
              name: 'case-number-section',
              condition: this.showCaseNumberSection
            }]
          }, {
            components: [{
              name: 'client-section',
              condition: this.showClientSection
            }]
          }, {
            components: [{
              name: 'payment-method-section',
              condition: this.showPaymentMethod
            }]
          }]
        };
      },
      adminLines() {
        return [{
          components: [{
            name: 'replacement-section',
            condition: this.showReplacementSection
          }]
        }, {
          components: [{
            name: 'manual-fees-section',
            condition: this.showManualFeeSection
          }]
        }, {
          components: [{
            name: 'confirmations-section',
            condition: this.showConfirmationsSection
          }]
        }, {
          components: [{
            name: 'pricing-templates-section',
            condition: this.showPricingTemplatesSection
          }]
        }, {
          components: [{
            name: 'auto-invite-section',
            condition: this.showAutoInviteSection,
            classes: ['post-form__field']
          }, {
            name: 'infinite-auto-invite-section',
            condition: this.showInfiniteAutoInviteSection,
            classes: ['post-form__field']
          }, {
            name: 'auto-approve-section',
            condition: this.showAutoApproveSection,
            classes: ['post-form__field']
          }]
        }, {
          components: [{
            name: 'standby-section',
            condition: this.showStandBySection,
            classes: ['post-form__field']
          }]
        }];
      },
      adminInformationSection() {
        return {
          title: this.adminInfoSectionTitle,
          classes: 'post-section--dark',
          lines: this.adminLines
        };
      },
      additionalLines() {
        return [{
          components: [{
            name: 'alternative-language-section',
            condition: this.showAlternativeLanguageSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'several-suppliers-section',
            condition: this.showSeveralSuppliersSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'direct-booking-section',
            params: {
              disabledFields: this.disabledImportantFields,
              showAssignmentHelper: true
            },
            condition: !this.showDirectInterpreterSection && this.userIsEnterpriseMember
          }]
        }, {
          components: [{
            name: 'gender-section',
            params: {
              disabledFields: this.disabledImportantFields,
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'qualification-section',
            condition: this.showQualificationSection,
            params: {
              disabledFields: this.disabledImportantFields,
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'blocked-suppliers-section',
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'blocked-cities-section',
            condition: this.showBlockedCitiesSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'extra-info-inner-section',
            params: {
              showExtraInfoNote: true,
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'attachments-inner-section',
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'participants-section',
            condition: this.showParticipantsSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }];
      },
      summaryObj() {
        return {
          btnText: this.reviewModalBtnText,
          job: {
            languageFromId: this.languageFrom,
            languageToId: this.languageTo,
            alternativeLanguageToId: this.alternativeLanguageTo,
            sessionType: this.assignmentType,
            qualificationId: this.qualification,
            startTime: this.fullStartTime,
            finishTime: this.fullFinishTime,
            sameLocationSuppliersList: this.showSameLocationSuppliersList
              ? this.sameLocationSuppliersList
              : '',
            sex: this.sex,
            confirmationBy: this.confirmationBy,
            booker: this.booker,
            ownerRealName: this.ownerRealName,
            dateTimes: this.dateTimes,
            department: {
              departmentId: this.department,
              departmentsList: this.departmentsList
            },
            attenders: {
              attenderIds: this.attenders,
              attendersList: this.attendersList
            },
            directInterpreterName: this.directInterpreterName,
            bookingRef: this.bookingRef,
            paymentBookingRef: this.paymentBookingRef,
            caseNumber: this.caseNumber,
            clientName: this.showClientSection ? this.clientName : undefined,
            clientId: this.showClientSection ? this.clientId : undefined,
            paymentMethod: {
              paymentMethodId: this.showPaymentMethod ? this.paymentMethod : undefined,
              paymentMethodsList: this.paymentMethods
            },
            dialect: this.dialect,
            subject: this.subject,
            category: {
              categoryId: this.category,
              categoriesList: this.categoriesList
            },
            extra: this.extraInfo,
            audience: this.accessibility || 'private',
            participantsEmails: this.participantEmails,
            participantsPhones: this.participantPhones,
            contactAttributes: this.assignmentType === 'in_person' ? {
              savedContactAddress: {
                id: this.contactAddressChosenID,
                savedContactAddressesList: this.savedContactAddresses
              },
              person: {
                name: this.contactPersonName,
                phone: this.contactPersonPhone
              },
              address: this.fullAddress,
              description: this.contactWayDescription
            } : {},
            confirmationByPhone: this.showConfirmationsSection ? this.confirmationByPhone : undefined,
            actualCreatedAt: this.showReplacementSection ? this.actualCreatedAt : undefined,
            bookingMechanism: this.showReplacementSection ? this.bookingMechanism : undefined,
            manualFees: this.showManualFeeSection ? this.manualFees : undefined,
            videoPlatform: {
              videoPlatformId: this.assignmentType === 'video' ? this.videoPlatformId : undefined,
              videoPlatformType: this.assignmentType === 'video' ? this.videoPlatformType : undefined,
              videoPlatformsList: this.videoPlatformsList || [],
              videoPlatformInstructions: this.assignmentType === 'video' ? this.videoPlatformInstructions : undefined,
              videoPlatformUrl: this.assignmentType === 'video' ? this.videoPlatformUrl : undefined
            },
            customPriceTemplates: {
              demanderInternalTemplate: this.showPricingTemplatesSection ? this.demanderInternalTemplate : undefined,
              demanderInternalPriceTemplatesList: this.demanderTemplates.internal,
              demanderExternalTemplate: this.showPricingTemplatesSection ? this.demanderExternalTemplate : undefined,
              demanderExternalPriceTemplatesList: this.demanderTemplates.external,
              supplierHonorarTemplate: this.showPricingTemplatesSection ? this.supplierHonorarTemplate : undefined,
              supplierHonorarPriceTemplatesList: this.supplierTemplates.honorar,
              supplierCompanyTemplate: this.showPricingTemplatesSection ? this.supplierCompanyTemplate : undefined,
              supplierCompanyPriceTemplatesList: this.supplierTemplates.company,
              supplierEmployeeTemplate: this.showPricingTemplatesSection ? this.supplierEmployeeTemplate : undefined,
              supplierEmployeePriceTemplatesList: this.supplierTemplates.employee,
            },
            processRequirement: {
              ...this.processRequirements,
              autoApprove: this.showAutoApproveSection ? this.autoApprove : undefined,
              standBy: this.showStandBySection ? this.standBy : undefined,
              seriesJobProcessing: this.isSeriesJobForm ? this.seriesJobProcessing : ''
            },
            blockedCities: this.blockedCities,
            blockedSuppliers: this.blockedSuppliers,
            attachments: this.attachments
          }
        };
      },
      directAwardButtonText() {
        return this.$gettext('Award interpreter');
      }
    },
    methods: {
      validateAdminSection() {
        return this.validateConfirmationBySection()
          & this.validateReplacementSection();
      },
      validateInvoiceSection() {
        return this.validateBookingReferenceSection()
          & this.validateCaseNumberSection()
          & this.validatePaymentMethodSection();
      },
      validatePersonalInfoSection() {
        return this.validateOwnerRealNameSection()
          & this.validateDepartmentAttendersSection();
      },
      validateMainAssignmentInfoSection() {
        return this.validateLanguagesSection()
          & this.validateMultipleDateTimeSection()
          & this.validateDateTimeSection()
          & this.validateSubjectSection()
          & this.validateAssignmentTypeSection()
          & this.validateProcessingSections();
      },
      validateMainSecretaryAssignmentInfoSection() {
        return this.validateLanguagesSection()
          & this.validateSecretarySection()
          & this.validateDeadlineSection()
          & this.validateSubjectSection();
      },
      validateSecondaryAssignmentInfoSection() {
        return this.validateParticipantsSection();
      },
      validatePage() {
        this.resetValidationResult();
        this.$store.commit('ErrorsStore/removeAllErrors');
        const adminInfoIsValid = this.validateAdminSection();
        const personalInfoIsValid = this.validatePersonalInfoSection();
        const invoiceInfoIsValid = this.validateInvoiceSection();
        const assignmentInfoIsValid = this.validateMainAssignmentInfoSection()
          & this.validateSecondaryAssignmentInfoSection();
        return personalInfoIsValid & assignmentInfoIsValid & adminInfoIsValid & invoiceInfoIsValid;
      },
      handleSuccess(newJobObj) {
        if (newJobObj?.job?.id) {
          this.$router.push({name: 'BuyerOneAssignment', params: {id: newJobObj.job.id}});
        }
      },
      sendInfoFunc() {
        return this.$store.dispatch('PostInterpretationStore/createJob');
      },
      openSupplierIsNotAvailableForBookModal() {
        setTimeout(() => {
          this.$store.commit('InfoModalStore/setInfoModal', {
            text: this.$gettext('Sorry, this interpreter is not available for booking. Please book another interpreter'),
            method: 'supplierIsNotAvailableForBookModalCallback',
            context: this
          });
        });
      },
      supplierIsNotAvailableForBookModalCallback() {
        this.$router.push(this.$makeRoute({
          name: 'BuyerInterpreters',
          query: {
            page: 1,
            availability_date: this.$moment()
              .format('YYYY-MM-DD'),
            langFrom: '3'
          }
        }));
      },
      handleError(error, context) {
        // see https://github.com/Skiwo/tt-dev/issues/5063
        const errorsListForQuickEditModal = [];

        this.$store.commit('PostingStore/ParticipantsStore/removeInviteesErrors');
        if (error?.status == 401) {
          this.$store.dispatch('UserInfoStore/fetchUserInfo', this.userUid)
            .then(() => {
              this.$emit('postassignfailed');
              this.$store.dispatch('ModalStore/closeModal');
            });
        } else {
          let isHandledError = false;
          const errorTemplate = this.$gettext('%{label} is required.');
          const errors = error?.data?.errors;

          if (errors?.secretary_callback_phone_number?.includes('Invalid phone')) {
            isHandledError = true;
            const errorText = this.$gettext('Phone number is invalid. Please check the number you have provided');

            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'SecretaryStore',
              fieldName: 'secretaryCallbackPhone',
              errorText
            });
            errorsListForQuickEditModal.push(errorText);
            this.$emit('postassignfailed');
          }
          if (errors?.secretary_contact_phone_number?.includes('Invalid phone')) {
            const errorText = this.$gettext('Phone number is invalid. Please check the number you have provided');

            isHandledError = true;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'SecretaryStore',
              fieldName: 'secretaryContactPhone',
              errorText
            });
            errorsListForQuickEditModal.push(errorText);
            this.$emit('postassignfailed');
          }
          if (errors?.valid_booking_reference?.includes('Booking Reference required')) {
            const errorText = this.$gettextInterpolate((errorTemplate), {
              label: this.$store.getters['UserInfoStore/labelForBookingRef'] || this.$gettext('Booking ref.')
            });

            isHandledError = true;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'BookingRefStore',
              fieldName: 'bookingRef',
              errorText,
              typeError: 'wrongData'
            });
            errorsListForQuickEditModal.push(errorText);
            this.$emit('postassignfailed');
          }
          if (errors?.valid_payment_booking_reference?.includes('Payment booking reference required')) {
            const error = this.$gettextInterpolate((errorTemplate), {
              label: this.$store.getters['UserInfoStore/labelForPaymentBookingRef'] || this.$gettext('Attestant BID')
            });

            isHandledError = true;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'BookingRefStore',
              fieldName: 'paymentBookingRef',
              errorText: error,
              typeError: 'wrongData'
            });
            this.$emit('postassignfailed');
          }
          if (errors?.valid_case_number?.includes('Case number required')) {
            const errorText = this.$gettextInterpolate((errorTemplate), {
              label: this.$store.getters['UserInfoStore/labelForCaseNumber'] || this.$gettext('Case number')
            });

            isHandledError = true;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'CaseNumberStore',
              fieldName: 'caseNumber',
              errorText,
              typeError: 'wrongData'
            });
            errorsListForQuickEditModal.push(errorText);
            this.$emit('postassignfailed');
          }
          if (errors?.interpretation_requirement?.includes('Past booking time')) {
            const errorText = this.$gettext('Deadline time can\'t be so soon');

            isHandledError = true;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'DeadlineStore',
              fieldName: 'deadlineTime',
              errorText,
              typeError: 'wrongData'
            });
            errorsListForQuickEditModal.push(errorText);
            this.$emit('postassignfailed');
          }
          if (errors?.interpretation_requirement?.start_time.includes('This assignment\'s start time can\'t be edited anymore')
            || errors?.interpretation_requirement?.finish_time.includes('This assignment\'s finish time can\'t be edited anymore')) {
            isHandledError = true;
            this.$emit('postassignfailed');
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Assignment time can no longer be edited')
              });
            });
          }
          if (errors['global!']) {
            isHandledError = true;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'PaymentMethodStore',
              fieldName: 'paymentMethod',
              errorText: errors['global!'][0],
            });
            this.$emit('postassignfailed');
          }
          if (errors?.interpretation_requirement?.includes('Session should be at least 1 hour')) {
            const errorText = this.$gettext('Minimal duration for this assignment is 1 hour');

            isHandledError = true;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'DateTimeStore',
              fieldName: 'startTime',
              errorText,
              typeError: 'wrongData'
            });
            errorsListForQuickEditModal.push(errorText);
            this.$emit('postassignfailed');
          }
          if (errors?.interpretation_requirement?.session_type?.includes('This assignment\'s session type can\'t be edited anymore')) {
            const errorText = this.$gettext('Assignment type can no longer be edited');

            isHandledError = true;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'AssignmentTypeStore',
              fieldName: 'assignmentType',
              errorText,
              typeError: 'wrongData'
            });
            errorsListForQuickEditModal.push(errorText);
            this.$emit('postassignfailed');
          }
          if (errors?.interpreter_uid?.includes('Unsuitable account status for this action')) {
            isHandledError = true;
            this.$emit('postassignfailed');
            this.openSupplierIsNotAvailableForBookModal();
          }
          if (errors?.cc_emails) {
            isHandledError = true;
            this.$store.commit('PostingStore/ParticipantsStore/setInviteesErrors', {
              data: error.data.errors.cc_emails,
              context: this
            });
            const errorText = this.$gettext('Sorry, you can not add this email.');
            errorsListForQuickEditModal.push(errorText);
            this.$emit('postassignfailed');
          }
          if (errors?.info?.confirmation_phones?.includes('One or more confirmation phones are invalid')) {
            const errorText = this.$gettext('SMS messages can be sent to valid mobile phone numbers. Please check the number you have provided.');

            isHandledError = true;
            this.$store.dispatch('PostingStore/ParticipantsStore/catchParticipantsSelect', true);
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'ParticipantsStore',
              fieldName: 'participantPhones',
              errorText
            });
            errorsListForQuickEditModal.push(errorText);
            this.$emit('postassignfailed');
          }
          if (errors?.time_available?.interpretation_requirement?.includes('This time isn\'t available')) {
            isHandledError = true;
            this.$emit('postassignfailed');
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('It seems you already have a event at the selected time in your calendar. Please choose a different time for this event.')
              });
            }, 0);
          }
          const departmentErrors = error?.data?.errors?.interpretation_requirement?.department_id
            && error.data.errors.interpretation_requirement.department_id.length
            ? error.data.errors.interpretation_requirement.department_id
            : [];
          let isParticipantsErrors = false;
          const participantsErrors = error?.data?.errors?.participants || {};

          for (const participantKey of Object.keys(participantsErrors)) {
            const participantErrors = participantsErrors[participantKey].uid
              && participantsErrors[participantKey].uid.length
              ? participantsErrors[participantKey].uid
              : [];
            if (participantErrors.includes('Wrong participant')
              || participantErrors.includes('All participants should be from corresponding department')
              || participantErrors.includes('All participants should be attenders')) {
              isParticipantsErrors = true;
            }
          }
          if (isParticipantsErrors
            || departmentErrors.includes('There is no relation with this id')
            || departmentErrors.includes('You are not belong to this department')) {
            const errorText = this.$gettext('It seems like something has been changed in your Enterprise. Please try again.');

            errorsListForQuickEditModal.push(errorText);
            isHandledError = true;
            this.$store.dispatch('PostingStore/DepartmentAttendersStore/fetchDepartments')
              .then(() => {
                this.$store.commit('executeNotTrackedMutation', {
                  mutationName: 'PostingStore/DepartmentAttendersStore/setDepartment',
                  payload: ''
                });
                this.$store.dispatch('PostingNotifierStore/preselectDepartment')
                  .then(() => {
                    this.setPostingError({
                      storeName: 'PostingStore',
                      subStoreName: 'DepartmentAttendersStore',
                      fieldName: 'department',
                      errorText
                    });
                    this.$store.dispatch('ModalStore/closeModal');
                    setTimeout(() => {
                      this.$store.commit('InfoModalStore/setInfoModal', {
                        text: errorText
                      });
                    }, 0);
                    setTimeout(() => {
                      this.$scrollToErrors();
                    }, 500);
                    this.$emit('postassignfailed');
                  });
              });
          }
          if (error?.data?.errors?.interpretation_requirement?.video_platform_id
            && error?.data?.errors?.interpretation_requirement?.video_platform_id?.length
            && (error.data.errors.interpretation_requirement.video_platform_id.includes('must be filled')
              || error.data.errors.interpretation_requirement.video_platform_id.includes('There is no relation with this id'))) {
            const errorText = this.$gettext('Please choose video platform type.');

            isHandledError = true;
            this.setPostingError({
              storeName: 'PostingStore',
              subStoreName: 'AssignmentTypeStore',
              fieldName: 'videoPlatformId',
              errorText: errorText,
              typeError: 'missingData'
            });
            errorsListForQuickEditModal.push(errorText);
            this.$emit('postassignfailed');
          }
          if (errors?.interpretation_requirement?.includes('Videoroom should be at least 1 hour')) {
            isHandledError = true;
            this.$emit('postassignfailed');
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettext('Minimal duration for videoroom assignment is 1 hour')
              });
            }, 0);
          }
          if (errors?.attachments) {
            const errorText = this.$gettext('Some errors with attachments');

            isHandledError = true;
            this.$store.commit('PostInterpretationStore/setAttachmentsErrors', {
              data: error.data.errors.attachments,
              context: this
            });
            errorsListForQuickEditModal.push(errorText);
            this.$emit('postassignfailed');
          }
          if (errors?.job_not_exists?.includes('Job exists')) {
            isHandledError = true;
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'same-job-modal',
                data: {context}
              });
            });
          }
          if (errors?.payment_method_id?.includes('This payment method is not valid for the selected job creator')) {
            isHandledError = true;
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              const template = this.$gettext('Payment method you are trying to use is no longer available or archived. Please select another payment method or visit <a class="sk-link sk-link--default" href="%{link}">the payment methods page</a> to add a new method');
              const link = this.$router.resolve(
                this.$makeRoute({
                  name: 'BuyerCompanyPayments'
                })
              ).href;

              this.$store.commit('InfoModalStore/setInfoModal', {
                text: this.$gettextInterpolate(template, {
                  link
                })
              });
            });
          }
          if (this.assignmentInfoEditModalErrors) this.assignmentInfoEditModalErrors = errorsListForQuickEditModal;
          if (!isHandledError) {
            this.$store.dispatch('ModalStore/closeModal');
            setTimeout(() => {
              this.$store.commit('ModalStore/setModal', {
                component: 'error-modal',
                data: {
                  error
                }
              });
            }, 0);
          }
          this.$scrollToErrors();
        }
      }
    },
    beforeRouteLeave(to, from, next) {
      // only if user leaves posting pages
      const assignmentTypeTo = to.meta.assignmentType || '';
      const assignmentTypeFrom = from.meta.assignmentType || '';
      if (!assignmentTypeTo || !assignmentTypeFrom) {
        this.$store.dispatch('PostInterpretationStore/clearPersonalInfoAndInvoiceStores');
      }
      this.$store.dispatch('PostInterpretationStore/clearAssignmentInfoStores');
      this.$store.commit('PostingStore/AssignmentTypeStore/clearVideoPlatformsList');
      next();
    }
  };
</script>
